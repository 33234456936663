import * as React from "react";
import CommentaryType from "../../../model/CommentaryType";

export interface CommentaryTypeSelectorProps {
  selectedCommentaryTypes: CommentaryType[];
  onCommentaryTypesUpdate: (newCommentaryTypes: CommentaryType[]) => void;
}

export default function CommentaryTypeSelector({ selectedCommentaryTypes, onCommentaryTypesUpdate }: CommentaryTypeSelectorProps): React.ReactElement {
  const isChecked = (type: CommentaryType) => {
    return (
      selectedCommentaryTypes.find((candidate) => {
        return candidate === type;
      }) !== undefined
    );
  };

  const getNewCommentaryTypes = (type: CommentaryType) => {
    let newCommentaryTypes = selectedCommentaryTypes.slice();
    if (isChecked(type)) {
      newCommentaryTypes = newCommentaryTypes.filter((candidate) => {
        return candidate !== type;
      });
    } else {
      newCommentaryTypes.push(type);
    }
    onCommentaryTypesUpdate(newCommentaryTypes);
  };

  return (
    <nav className="panel">
      <p className="panel-heading">Commentary Type</p>
      <div className="panel-block">
        <div className="control">
          <div className="field">
            <label className="checkbox">
              <input
                type="checkbox"
                onChange={() => getNewCommentaryTypes(CommentaryType.SMURF)}
                checked={isChecked(CommentaryType.SMURF)}
              />{" "}
              Smurf
            </label>
          </div>
          <div className="field">
            <label className="checkbox">
              <input
                type="checkbox"
                onChange={() => getNewCommentaryTypes(CommentaryType.HIGH_ELO)}
                checked={isChecked(CommentaryType.HIGH_ELO)}
                />{" "}
              	High Elo
            </label>
          </div>
        <div className="field">
            <label className="checkbox">
              <input
                type="checkbox"
                onChange={() => getNewCommentaryTypes(CommentaryType.EARPIECE)}
                checked={isChecked(CommentaryType.EARPIECE)}
                />{" "}
              	Earpiece
            </label>
          </div>
        </div>
      </div>
    </nav>
  );
}
