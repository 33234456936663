import * as React from "react";
import { Modal } from "./Modal";
import { Media, Hls } from '@vidstack/player-react';
import { Watchable } from "../../model/WatchStatus";
import { getStreamUrl } from "../../utils/UrlUtilities";
import { isCourse } from "../../model/Course";

export interface VideoModalProps {
  onClose: () => void;
  isVisible: boolean;
  currentWatchable?: Watchable;
}

export function VideoModal({ onClose, isVisible, currentWatchable }: VideoModalProps): React.ReactElement {
    if (! isVisible || ! currentWatchable || isCourse(currentWatchable)) {
      return (
        <Modal title='' onClose={onClose} isVisible={isVisible}>
        </Modal>
      )
    }

    const { title, description, imageUrl } = currentWatchable

    return (
      <Modal title={title} onClose={onClose} isVisible={isVisible}>
        <p>{description}</p>
        <Media>
          <Hls autoplay controls poster={imageUrl}>
            <video controls src={getStreamUrl(currentWatchable)} preload="none" data-video="0" />
          </Hls>
        </Media>
      </Modal>
    );
}

