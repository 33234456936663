import { isCommentary } from "./Commentary";

enum CommentaryType {
  SMURF,
  HIGH_ELO,
  EARPIECE,
}

export function getCommentaryType(input: unknown): CommentaryType | undefined {
  if (isCommentary(input)) {
    if (input.type === "Smurf") {
      return CommentaryType.SMURF
    } else if (input.type === "High Elo") {
      return CommentaryType.HIGH_ELO
    } else if (input.type === "Earpiece") {
      return CommentaryType.EARPIECE
    }
  }

  return undefined
}

export default CommentaryType
